import React from "react"

import LayoutScene from "../../components/layout-scene"
import IndexPage from "../resume"
import SEO from "../../components/seo"
// import Scene from  "../components/scene"
import Scene from "../../components/scenes/maps-room"

// import Scene from "../components/scenes/3d-loader";



const Interactive = () => (
    <LayoutScene>
        <SEO title="Maps" />
        <div style={{position:`relative`, margin: `0`, padding:`0`, border:`0`}}>
            <Scene />
        </div>
    </LayoutScene>
)

export default Interactive