import React, { useRef } from 'react';
import { HemisphericLight } from '@babylonjs/core/Lights/hemisphericLight';
import { Vector3 } from '@babylonjs/core/Maths/math';
import BabylonScene from 'babylonjs-hook';
import '../layout.css';

import {
    StandardMaterial,
    TransformNode, GlowLayer, GizmoManager, AbstractMesh
} from "@babylonjs/core";
import {Color3} from "@babylonjs/core/Maths/math";

import {ArcRotateCamera} from "@babylonjs/core";
import {SceneLoader} from "@babylonjs/core";
import * as GUI from "@babylonjs/gui";


let tiefighter;
let babylonLink;

var tunnel;


const onSceneReady = scene => {

    // scene.clearColor = new Color3(0.5, 0.8, 0.5);
    // scene.clearColor = new Color3(73, 80, 87); was this one
    scene.clearColor = new Color3(0.0234375,0.0234375,0.0234375); // CHECK

    const canvas = scene.getEngine().getRenderingCanvas();

    const camera = new ArcRotateCamera("cam", -Math.PI , Math.PI / 2, 5, new Vector3(0,0,0));
    const anchor = new TransformNode("");

    camera.wheelDeltaPercentage = 0.05;
    camera.lowerRadiusLimit = 0; // 3.5
    camera.upperRadiusLimit = 15;  // 6
    // camera.lowerBetaLimit = -Math.PI + -Math.PI /2;


    var light = new HemisphericLight("light", new Vector3(0, 1, 0), scene);

    // Default intensity is 1. Let's dim the light a small amount
    light.intensity = 0.85;


    // Gizmo control
    var gizmoManager = new GizmoManager(scene);
    gizmoManager.positionGizmoEnabled = true;
    gizmoManager.rotationGizmoEnabled = true;
    gizmoManager.scaleGizmoEnabled = true;
    gizmoManager.boundingBoxGizmoEnabled = true;

    var tieMaterial = new StandardMaterial;
    tieMaterial.name = "tieMat";
    tieMaterial.diffuseColor = new Color3.Blue;

    // var meshisin = AbstractMesh;
    // var skullMesh;
    // meshisin = SceneLoader.ImportMesh("",
    //     "https://raw.githubusercontent.com/FTi130/gatsby-site/master/src/components/assets/",
    //     "tunnel.babylon",
    //     scene,
    //     function(newMeshes)
    //     {
    //
    //         skullMesh = newMeshes[0];
    //         skullMesh.position.y = 100;
    //         skullMesh.rotation = new Vector3(Math.PI/2, Math.PI/2, 0)
    //     });
    //
    // setTimeout(function() {
    //     skullMesh.position.y = 100;
    //     skullMesh.rotation = new Vector3(Math.PI/2, Math.PI/2, 0)
    // }, );


    // tunnel = SceneLoader.ImportMesh("",
    //     "https://raw.githubusercontent.com/FTi130/gatsby-site/master/src/components/assets/",
    //     "tunnel.babylon",
    //     scene,
    //     function(newMeshes)
    //     {
    //         // console.log(scene.meshes[0].name);
    //         const root_obj = scene.meshes[0];
    //         console.log(scene.meshes[0]);
    //
    //
    //         scene.meshes[0].dispose();
    //         // root_obj.rotate(5);
    //
    //         // root_obj.getChildren().forEach(mesh =>{
    //         //    mesh.parent = null;
    //         //    console.log(mesh.name);
    //         //
    //         //
    //         // });
    //         // root_obj.dispose();
    //         root_obj.position = new Vector3(100, 0.005, 1)
    //
    //         var sphere = newMeshes[0].getChildMeshes()[0];
    //         sphere.material = tieMaterial;
    //
    //
    //
    //         setTimeout(function() {
    //             root_obj.position.y = 100;
    //             root_obj.rotation = new Vector3(Math.PI/2, Math.PI/2, 0)
    //         }, );
    //
    //     });



    camera.attachControl(canvas, true);

    const manager = new GUI.GUI3DManager(scene);

    // SpherePanelを生成
    const panel = new GUI.SpherePanel();
    panel.margin = 0.4;
    panel.radius = 3.5;


    manager.addControl(panel);
    panel.linkToTransformNode(anchor);
    panel.position.z = 1.2;  // -1.5


    // Materials
    var webMaterial = new StandardMaterial("webmaterial", scene);
    //boxMaterial.emissiveColor = new Color3(0, 0.58, 0.86);
    webMaterial.emissiveColor = new Color3(0.15, 0.15, 0.86);








    const buttonFuture = new GUI.HolographicButton("future");
    panel.addControl(buttonFuture);

    var textFuture = new GUI.TextBlock();
    textFuture.text = "Future";
    textFuture.color = "#034067";
    textFuture.fontSize = 74;
    textFuture.fontWeight = "bold";

    buttonFuture.content = textFuture;

    buttonFuture.onPointerClickObservable.add(function() {

        window.open("/maps/where-to-go/",'_self',false);
    });





    const buttonStories = new GUI.HolographicButton("stories");
    panel.addControl(buttonStories);

    var textStories = new GUI.TextBlock();
    textStories.text = "Stories";
    textStories.color = "#2626DB";
    textStories.fontSize = 74;
    textStories.fontWeight = "bold";

    buttonStories.content = textStories;


    buttonStories.onPointerClickObservable.add(function() {

        window.open("/trips/",'_self',false);
    });


    const buttonPast = new GUI.HolographicButton("past");
    panel.addControl(buttonPast);

    var textPast = new GUI.TextBlock();
    textPast.text = "Past";
    textPast.color = "#034067";
    textPast.fontSize = 74;
    textPast.fontWeight = "bold";

    buttonPast.content = textPast;


    buttonPast.onPointerClickObservable.add(function() {

        window.open("/maps/geoarchive/",'_self',false);
    });





    panel.blockLayout = false;

}

/**
 * Will run on every frame render.  We are spinning the box on y-axis.
 */
const onRender = scene => {
    // if (box !== undefined) {
    //     var deltaTimeInMillis = scene.getEngine().getDeltaTime();
    //
    //     const rpm = 10;
    //     box.rotation.y += ((rpm / 80) * Math.PI * 2 * (deltaTimeInMillis / 1000));
    //     box.rotation.z += ((rpm / 100) * Math.PI * 2 * (deltaTimeInMillis / 1000));
    //
    // }
    // if (me !== undefined) {
    //     var deltaTimeInMillis = scene.getEngine().getDeltaTime();
    //
    //         const rpm = 10;
    //         me.rotation.y += ((rpm / 80) * Math.PI * 2 * (deltaTimeInMillis / 1000));
    //
    // }

}

export default () => {
    babylonLink = useRef(null);

    return (
        <>
            {/*<Link to="/">*/}
            {/*    <BabylonScene antialias onSceneReady={onSceneReady} onRender={onRender} id='render-canvas' />*/}
            {/*</Link>*/}

            {/*<BabylonScene antialias onSceneReady={onSceneReady} onRender={onRender} id='render-canvas' />*/}
            {/*<a ref={babylonLink} target="_self" rel="noopener noreferrer" href="https://pavli.netlify.app/">*/}
            {/*</a>*/}


            <BabylonScene antialias onSceneReady={onSceneReady} onRender={onRender} id='render-canvas' />
            <a ref={babylonLink} target="_self" rel="noopener noreferrer" href="https://pavli.netlify.app/">
            </a>
            {/*This works*/}
            {/*<BabylonScene antialias onSceneReady={onSceneReady} onRender={onRender} id='render-canvas' />*/}


        </>
    )
}
